<template>
  <div class="add-users">
    <!-- <div class="pb-2" style="background: #fff">
      <div style="margin: 10px;">
        <span style="color:#cad4dd; font-family:Muli-Regular; font-size:12px"
          >Users ></span
        >
      </div>
      <span
        id="user"
        style="color:#1b1c1d; margin: 10px; font-size:19px; font-family:Muli-Regular; font-weight:bold"
        >USERS</span
      >
    </div> -->
    <p class="mb-0 ml-2 d-flex justify-content-between" style="font-size:16px; color:#1b1c1d;font-weight:600">
        Add New Users
        <span class="mr-2"> 
            <img  @click="goToUserHome"  style="cursor: pointer;" src="../../assets/SVG/Back.svg" width="60px">
        </span>
      </p>

    <b-card class="card m-2">
      
      <div class="row justify-content-around">
        <w-text-input
          :fieldType="'text'"
          :labelName="'First Name'"
          :validation="'required|regex_userName'"
          :className="'w-100 col-md-4'"
          :placeholder="'First Name'"
          :disabled="false"
          :defaultValue="firstName"
          :fieldName="'first name'"
          :labelStyle="'register-label'"
          @textInputChange="onEnterFirstName($event)"
        />
        <w-text-input
          :fieldType="'text'"
          :labelName="'Last Name'"
          :validation="'required|regex_userName'"
          :className="'w-100 col-md-4'"
          :placeholder="'Last Name'"
          :disabled="false"
          :defaultValue="lastName"
          :fieldName="'last name'"
          :labelStyle="'register-label'"
          @textInputChange="onEnterLasttName($event)"
        />
        <w-text-input
          :fieldType="'text'"
          :labelName="'Email ID'"
          :validation="'required|regex_Email'"
          :className="'w-100 col-md-4'"
          :placeholder="'Email-Id'"
          :disabled="false"
          :defaultValue="enterEmail"
          :fieldName="'email'"
          :labelStyle="'register-label'"
          @textInputChange="onEnterEmail($event)"
        />
      </div>

      <div class="row">
        <div class="col-lg-4">
          <label class="p-0 adduser_label">Status</label>
          <w-dropdown
            :label-text="''"
            :selectedValue="null"
            :selectOption="statusListOptions"
            @selectedOption="onSelectedUserStatus($event)"
          />
        </div>
        <div class="col-lg-4 mobile">
          <!-- <w-text-input
            :fieldType="'text'"
            :labelName="'Mobile Number'"
            :validation="'required|regex_Email'"
            :className="'w-100'"
            :placeholder="'Enter Mobile No.'"
            :disabled="false"
            :defaultValue="enterMobile"
            :labelStyle="'register-label'"
            @textInputChange="onEnterMobileNumber($event)"
          /> -->
          <label class="p-0 adduser_label"> Mobile Number </label><br />
          <input
            v-model="enterMobile"
            type="text"
            @input="acceptNumber"
            placeholder="Enter Mobile No."
          />
        </div>
        <div class="col-lg-12">
          <label class="p-0 adduser_label"
            >Roles <!--<span class="warning_icon">!</span>-->
          </label>
          <!-- <w-dropdown
            :selectedValue="null"
            :selectOption="roleListOptions"
            @selectedOption="getUserRoleList($event)"
          /> -->
          <!-- <TreeDropdown
            :rootOptions="roleOptions"
            :selectedValue="roleSelected"
            @selectedField="getSelectedRoles($event)"
          /> -->
          <div>
            <label v-for="(role,index) in roleOptions" :key="index" :id="`role${index}`" class="role-wrapper" @click="selectRole(role.id,index)">
              {{role.label}}
            </label>
          </div>
        </div>
      </div>

      <div class="row mt-3 d-flex justify-content-end btn-groups">
        <!-- <button
          class="save-cancel_btn mr-3"
          @click="showConfirmModal()"
          :disabled="isSaveBtnDisabled"
          style="background:#5fa6e7; color:#fff"
        >
          Save
        </button>
        <button
          class="save-cancel_btn mr-3"
          @click="goToUserHome()"
          style="background:#fff; color:#2e8cd3;"
        >
          Cancel
        </button> -->
        <w-button-input @buttonClicked="showConfirmModal" :disabled="isSubmitBtnDisabled" :label="'Save'" :buttonStyle="'generate-btn'" :buttonClass="'generate-btn'" />
        <w-button-input @buttonClicked="goToUserHome" :label="'Cancel'" :buttonStyle="'generate-clear'" :buttonClass="'generate-btn'" />
      </div>
    </b-card>

    <!-- error handling -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>
<script>
import TextInput from "../../widgets/TextInput.vue";
import Dropdown from "../../widgets/Dropdown.vue";
import Modal from "../../widgets/ModalWidget";
import TreeDropdown from "../../widgets/TreeDropdown";
import {
  getUserStatus,
  getUserRoles,
  postUserDetails,
} from "../../Service/UserService";
import $ from "jquery";
import ButtonInput  from "../../widgets/InputButton.vue";

export default {
  components: {
    "w-text-input": TextInput,
    "w-dropdown": Dropdown,
    Modal,
    TreeDropdown,
    "w-button-input":ButtonInput
  },
  data() {
    return {
      // for error modal
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      firstName: "",
      lastName: "",
      enterEmail: "",
      enterMobile: "",

      selectedStatusListValue: "",
      statusListOptions: [],

      // selectedRoleListValue: "",
      // roleListOptions: [],

      roleOptions: [],
      roleSelected: [],
    };
  },

  created() {
    this.getUserStatus();
    this.getUserRoles();
  },

  // for disabled save button
  beforeUpdate() {
    this.isSaveBtnDisabled = ![
      this.firstName,
      this.lastName,
      this.enterEmail,
      this.enterMobile,
    ].every(Boolean);
  },

  methods: {
    selectRole(id,index){
      if(this.roleSelected.indexOf(id) !== -1){
        this.roleSelected.splice(this.roleSelected.indexOf(id),1);
        // console.log(this.roleSelected, " roleselectesd if",this.roleSelected.indexOf(id))
        // document.getElementById(`role${index}`)
        $(`#role${index}`).removeClass('active')
      }else{
        this.roleSelected.push(id);
        // console.log(this.roleSelected, " roleselectesd else",this.roleSelected.indexOf(id))
        $(`#role${index}`).addClass('active')
      }
    },
    acceptNumber() {
      var x = this.enterMobile
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.enterMobile = !x[2] ? x[1] : +x[1] + x[2] + (x[3] ? +x[3] : "");
      // console.log("nomber ", this.enterMobile);
    },

    getUserStatus() {
      this.statusListOptions = [];
      var status = [];
      this.statusListOptions.push({
        value: null,
        text: "Select Status",
      });
      getUserStatus()
        .then((res) => {
          status = res || [];
          status.map((element) => {
            this.statusListOptions.push({
              value: element,
              text: element,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getUserRoles() {
      // this.roleListOptions = [];
      // var roles = [];
      // this.roleListOptions.push({
      //   value: null,
      //   text: "Select Role",
      // });
      getUserRoles()
        .then((res) => {
          var roles = [];
          roles = res || [];
          // roles.map((element) => {
          //   this.roleListOptions.push({
          //     value: element.roleName,
          //     text: element.roleDisplayName,
          //   });
          // });
          roles.forEach((element) => {
            this.roleOptions.push({
              id: element.roleName,
              label: element.roleDisplayName,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    postUserDetails() {
      let userData = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.enterEmail,
        mobileNumber: this.enterMobile,
        // roleNames: this.selectedRoleListValue,
        roleNames: this.roleSelected,
        // userStatus: this.selectedStatusListValue,
      };
      postUserDetails(userData)
        .then((res) => {
          // console.log("post data => ", res);
          this.goToUserHome();
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    onEnterFirstName(event) {
      this.firstName = event;
    },

    onEnterLasttName(event) {
      this.lastName = event;
    },

    onEnterEmail(event) {
      this.enterEmail = event;
    },

    // onEnterMobileNumber(event) {
    //   this.enterMobile = event;
    // },

    onSelectedUserStatus(event) {
      this.selectedStatusListValue = event;
    },
    // getUserRoleList(event) {
    //   this.selectedRoleListValue = event;
    // },
    getSelectedRoles(event) {
      // console.log("role event => ", event);
      this.roleSelected = event;
    },
    goToUserHome() {
      this.$router.push({ path: "/user" });
    },

    // close error modal
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    showConfirmModal() {
      this.alertMsg = "Are you sure want to Add new User ?";
      this.alert = true;
      this.alertType = "";
      this.isConfirmAlert = true;
    },
    confirmOk() {
      this.postUserDetails();
    },
  },
};
</script>

<style scoped>
.add-users{
  padding: 0rem 3% 0% 4%;
}
.add-users ::v-deep .custom-select,
.add-users ::v-deep .form-control {
  height: 37px !important;
}
.add-users ::v-deep #app {
  display: flex;
}
.adduser_label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  color: #767676;
}
.adduser_label .warning_icon {
  border: 1px solid #f2943a;
  border-radius: 50%;
  font-size: 7px;
  padding: 1px 4px;
  color: #f2943a;
  vertical-align: middle;
  cursor: pointer;
}
.save-cancel_btn {
  border-radius: 2px;
  border: solid 1px #2e8cd3;
  background-color: #2e8cd3;
  color: #fff;
  padding: 4px 50px;
  font-size: 16px;
  font-family: "proxima-nova", sans-serif !important;
}
.mobile input{
  border: 1px solid #ced4da;
  width: 100%;
  height: 37px;
  border-radius: .25rem;
  padding: 12px;
  font-size: 14px;
  color: #495057;
}
.mobile ::placeholder{
  color: #767676;
  font-size: 14px;
  opacity: 0.6;
}
.role-wrapper{
  padding: .5rem .8rem;
  border: 1px solid #023449;
  border-radius: 5px;
  cursor: pointer;
  color:#023449;
  transition-duration: .3s;
  font-size: 14px;
  margin-right: .5rem;
}
.role-wrapper.active{
  background-color: #023449;
  color:#FFFFFF;
}
.role-wrapper:hover{
  background-color: #023449;
  color:#FFFFFF;
}
@media screen and (min-width:800px) and (max-width:991px) {
  .add-users{
    padding: 0rem 3% 0% 7%;
  }
}
@media screen and (max-width:425px) {
  .btn-groups ::v-deep button{
    max-width: 8rem;
  }
}
</style>
